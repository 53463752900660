
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";
import { MenuComponent } from "@/assets/ts/components";
import {
  KycType,
  KycState,
  KycStatus,
  CardComponentsType,
} from "@/core/directive/type/kyc";
import { getKycCommonTypeParams } from "@/core/directive/function/kyc";
import { ApiKyc } from "@/core/api";
import { IPagination } from "@/core/directive/interface/common";
import mixin from "@/mixins";
import {
  ContactInfo,
  ContactInfoData,
  LegalInfo,
  legalInfoData,
  BillingAddressInfo,
  billingAddressInfoData,
  WarehouseInfo,
  warehouseInfoData,
  InventoryInfo,
  inventoryInfoData,
  FinancialInfo,
  financialInfoData,
  TaxInfo,
  taxInfoData,
} from "@/core/directive/interface/kyc";
import ContactCard from "@/components/page/kyc/common/ContactCard.vue";
import RegistrationCard from "@/components/page/kyc/common/RegistrationCard.vue";
import BillingCard from "@/components/page/kyc/common/BillingCard.vue";
import WarehouseCard from "@/components/page/kyc/common/WarehouseCard.vue";
import InventoryCard from "@/components/page/kyc/common/InventoryCard.vue";
import FinancialCard from "@/components/page/kyc/common/FinancialCard.vue";
import TaxCard from "@/components/page/kyc/common/TaxCard.vue";
import RejectModal from "@/components/page/kyc/kyc-confirmation/RejectModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  components: {
    ContactCard,
    RegistrationCard,
    BillingCard,
    WarehouseCard,
    InventoryCard,
    FinancialCard,
    TaxCard,
    RejectModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    const { showServerErrorMsg, showFormSubmitSuccessMsg } = mixin();

    const modalLoading = ref(false);
    const applicationScenarios =
      getKycCommonTypeParams.value.applicationScenarios;
    const settlementEntityId = route.params.id;
    const rejectId = ref("");
    const updateType = ref("");

    const contactView = ref<ContactInfo>(ContactInfoData);
    const registrationView = ref<LegalInfo>(legalInfoData);
    const billingList = ref<BillingAddressInfo[]>([billingAddressInfoData]);
    const warehouseList = ref<WarehouseInfo[]>([warehouseInfoData]);
    const inventoryList = ref<InventoryInfo[]>([inventoryInfoData]);
    const financialList = ref<FinancialInfo[]>([financialInfoData]);
    const taxList = ref<TaxInfo[]>([taxInfoData]);

    const cardComponentsLoading = ref({
      [CardComponentsType.Contact]: false,
      [CardComponentsType.Registration]: false,
      [CardComponentsType.Billing]: false,
      [CardComponentsType.Warehouse]: false,
      [CardComponentsType.Inventory]: false,
      [CardComponentsType.Financial]: false,
      [CardComponentsType.Tax]: false,
    });

    /**
     * 页面控制
     */

    // 分页
    const billingPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const warehousePagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const inventoryPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const financialPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const taxPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });

    // 按钮显示
    const buttonShowControl = (item?: any, length?: any) => {
      const buttonShow = {
        reject: false,
        approve: false,
      };
      if (item) {
        if (item.identification.status === KycStatus.SubmitToApproval) {
          buttonShow.reject = true;
          buttonShow.approve = true;
        }
      }
      return buttonShow;
    };

    const isNullData = (params: any) => {
      let isNull =
        params === null ||
        params === undefined ||
        JSON.stringify(params) === "[]" ||
        JSON.stringify(params) === "{}" ||
        JSON.stringify(params) === `""`;
      return isNull;
    };

    /**
     * 请求数据
     */

    // 联系信息
    const getContactStateAddress = async () => {
      cardComponentsLoading.value[CardComponentsType.Contact] = true;
      const { data } =
        await getKycCommonTypeParams.value.getContactStateAddressApi({
          application_scenarios: applicationScenarios,
          settlement_entity_id: settlementEntityId,
        });
      cardComponentsLoading.value[CardComponentsType.Contact] = false;
      if (data.code === 0) {
        contactView.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };
    // 注册信息
    const getRegistrationStateAddress = async () => {
      cardComponentsLoading.value[CardComponentsType.Registration] = true;
      const { data } =
        await getKycCommonTypeParams.value.getRegistrationStateAddressApi({
          application_scenarios: applicationScenarios,
          settlement_entity_id: settlementEntityId,
        });
      cardComponentsLoading.value[CardComponentsType.Registration] = false;
      if (data.code === 0) {
        registrationView.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };
    // 账单信息
    const getBillingList = async () => {
      cardComponentsLoading.value[CardComponentsType.Billing] = true;
      const { data } = await ApiKyc.getBillingList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: billingPagination.value.page,
        page_size: billingPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Billing] = false;
      if (data.code === 0) {
        billingList.value.splice(
          0,
          billingList.value.length,
          ...data.data.items
        );
        billingPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
    };
    // 仓库信息
    const getWarehouseList = async () => {
      cardComponentsLoading.value[CardComponentsType.Warehouse] = true;
      const { data } = await ApiKyc.getWarehouseList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: warehousePagination.value.page,
        page_size: warehousePagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Warehouse] = false;
      if (data.code === 0) {
        warehouseList.value.splice(
          0,
          warehouseList.value.length,
          ...data.data.items
        );
        warehousePagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
    };
    // 库存信息
    const getInventoryList = async () => {
      cardComponentsLoading.value[CardComponentsType.Inventory] = true;
      const { data } = await ApiKyc.getInventoryList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: inventoryPagination.value.page,
        page_size: inventoryPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Inventory] = false;
      if (data.code === 0) {
        inventoryList.value.splice(
          0,
          inventoryList.value.length,
          ...data.data.items
        );
        inventoryPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
    };
    // 财务信息
    const getFinancialList = async () => {
      cardComponentsLoading.value[CardComponentsType.Financial] = true;
      const { data } = await ApiKyc.getFinancialList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: financialPagination.value.page,
        page_size: financialPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Financial] = false;
      if (data.code === 0) {
        financialList.value.splice(
          0,
          financialList.value.length,
          ...data.data.items
        );
        financialPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
    };
    // 税务信息
    const getTaxList = async () => {
      cardComponentsLoading.value[CardComponentsType.Tax] = true;
      const { data } = await ApiKyc.getTaxList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: taxPagination.value.page,
        page_size: taxPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Tax] = false;
      if (data.code === 0) {
        taxList.value.splice(0, taxList.value.length, ...data.data.items);
        taxPagination.value.total == data.data.total;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getAllList = async () => {
      loading.value = true;
      Promise.all([
        getContactStateAddress(),
        getRegistrationStateAddress(),
        getBillingList(),
        getWarehouseList(),
        getInventoryList(),
        getFinancialList(),
        getTaxList(),
      ])
        .then(() => {
          loading.value = false;
          MenuComponent.reinitialization();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onRejectClick = (id, type) => {
      rejectId.value = id;
      updateType.value = type;
    };

    const kycApprove = async (id, type) => {
      const { data } = await ApiKyc.ApproveKycConfirmation({
        id: id,
        action: "approve",
      });
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          updateCardInfo(type);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const onApproveClick = (id, type) => {
      Swal.fire({
        text: "Approve this information?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.noCancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          kycApprove(id, type);
        }
      });
    };

    /**
     * 更新指定模块信息
     */

    const updateCardInfo = (type) => {
      if (type == CardComponentsType.Contact) {
        getContactStateAddress();
      } else if (type == CardComponentsType.Registration) {
        getRegistrationStateAddress();
      } else if (type == CardComponentsType.Billing) {
        getBillingList();
      } else if (type == CardComponentsType.Warehouse) {
        getWarehouseList();
      } else if (type == CardComponentsType.Inventory) {
        getInventoryList();
      } else if (type == CardComponentsType.Financial) {
        getFinancialList();
      } else if (type == CardComponentsType.Tax) {
        getTaxList();
      }
    };

    onMounted(() => {
      getAllList();
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      loading,
      modalLoading,
      cardComponentsLoading,
      getKycCommonTypeParams,
      KycType,
      KycStatus,
      CardComponentsType,
      //请求数据
      contactView,
      registrationView,
      billingList,
      warehouseList,
      inventoryList,
      financialList,
      taxList,
      //分页
      billingPagination,
      warehousePagination,
      inventoryPagination,
      financialPagination,
      taxPagination,
      //按钮显示
      buttonShowControl,
      isNullData,
      // 确认或驳回
      onRejectClick,
      onApproveClick,
      rejectId,
      updateType,
      updateCardInfo,
    };
  },
});
