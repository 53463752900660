
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import { useRoute, useRouter } from "vue-router";
import { NumberOrString } from "@/core/directive/type/common";
import { getKycConfirmationTypeParams } from "@/core/directive/function/kyc";
import { ApiKyc } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "add-kyc-verification-modal",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  emits: ["update-overview", "reset-form", "modal-hide"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const RejectKycConfirmationModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const rejectId = ref(props.id);

    const formData = ref({
      comments: "",
    });

    const rules = ref({
      comments: [
        {
          required: true,
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          rejectId.value = newVal;
        }
      }
    );

    const kycReject = async () => {
      loading.value = true;
      const { data } = await ApiKyc.ApproveKycConfirmation({
        id: rejectId.value,
        action: "disapprove",
        comments: formData.value.comments
      });
      loading.value = false;
      if (data.code === 0) {
        showFormSubmitSuccessMsg(() => {
          updateOverview();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          Swal.fire({
            text: "Reject this information?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: t("common.yes"),
            cancelButtonText: t("common.noCancel"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-light",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              kycReject();
            }
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      router.replace({ path: goPath.value });
    };

    const updateOverview = () => {
      emit("update-overview");
      modalHide();
    };

    const modalShow = () => {
      showModal(RejectKycConfirmationModalRef.value);
    };

    const modalHide = () => {
      hideModal(RejectKycConfirmationModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const goPath = computed(() => {
      return getKycConfirmationTypeParams.value.routerUrl;
    });

    onMounted(() => {
      modalShowListener(RejectKycConfirmationModalRef.value, () => {
        // setTimeout(() => {
        //   getShowInfo();
        // }, 0);
      });
      modalHideListener(RejectKycConfirmationModalRef.value, () => {
        resetForm();
        // emit("modal-hide");
      });
    });

    return {
      props,
      router,
      loading,
      formData,
      rules,
      formRef,
      RejectKycConfirmationModalRef,
      modalShow,
      modalHide,
      submit,
      updateOverview,
    };
  },
});
